import { render, staticRenderFns } from "./SkeletonGroupCard.vue?vue&type=template&id=62f53883&scoped=true&lang=pug&"
import script from "./SkeletonGroupCard.vue?vue&type=script&lang=ts&"
export * from "./SkeletonGroupCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f53883",
  null
  
)

export default component.exports