<template lang="pug">
    skeleton(w="100%" h="180px" m="0 17px 17px 0" radius="5px")
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    components : {
        Skeleton: ()=>import('skillbase-component/src/components/common/Skeleton.vue')
    }
})
</script>

<style lang="scss" scoped>

</style>
